import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInputSelect from '../../../core/form/components/FormInputSelect';

import FormBase from '../../../core/form/components/FormBase';
import { initControls, saveControls } from '../../../core/form/lib/validateForm';
import { controls as assocControls } from '../forms/harvestgroup_assoc';

import {
   createHarvestGroupAssoc,
   removeHarvestGroupAssoc,
   fetchHarvestGroupAssocs
} from '../actions';

class HarvestGroupAssocModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Harvest Group',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(assocControls),
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    const { fetching, harvestgroups } = this.props.season;
    const { isOpen } = this.state;
    const { orgs, portalUsers } = this.props.manage;

    let { id, isNew, title, controls, data } = this.state;

    const noneSelected = [{id: null, name: 'None Selected'}];
    controls.primary_contact_id.options = noneSelected.concat(portalUsers.map(portalUser => ({id: portalUser.id, name: portalUser.name})));
    controls.owner_id.options = noneSelected.concat(orgs.map(org => ({id: org.id, name: org.name})));

    if (isOpen !== this.props.isOpen && isOpen === false && !fetching) {
      this.setState({isOpen: this.props.isOpen});

      isNew = true;
      title = 'New Harvest Group';
      controls = initControls(cloneDeep(assocControls));

      id = null;
      data = {};

      this.setState({
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls
      });
    }
  }

  async onSave() {
    const { controls, isNew, data, id } = this.state;
    const formData = saveControls(controls, data);

    let success;
    if (isNew) {
      delete formData.id;
      formData.group_id = this.props.group_id;
      success = await this.props.dispatch(createHarvestGroupAssoc(formData));
    } else {
    //   success = await this.props.dispatch(updateHarvestGroup(formData));
    }

    if (success) {
      this.props.dispatch(fetchHarvestGroupAssocs({group_id: formData.group_id}));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      'Removing Harvest Group Association permanently. \n\nThis action can not be undone. Continue?',
    );

    if (confirmed) {
      const success = await this.props.dispatch(removeHarvestGroupAssoc(data.id));
      if (success) {
        this. props.dispatch(fetchHarvestGroupAssocs(this.props.params));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const { controls, isOpen, title, isNew } = this.state;
    const { responseMessage } = this.props.season;
    const iconName = 'people-group';

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'owner_id'}  })
              }
              control={controls.owner_id}
            />
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'primary_contact_id'}  })
              }
              control={controls.primary_contact_id}
            />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>Save</Button>
            <Button size="sm" color="light" onClick={this.onCancel}>Cancel</Button>
          </div>
          {!isNew && (
            <Button disabled size="sm" color="danger" onClick={this.onRemove} >Delete</Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers, season, manage }) => ({
  loggers,
  season,
  manage,
  vehicles
});

export default connect(mapStoreToProps)(HarvestGroupAssocModal);
