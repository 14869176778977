import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import { Button } from 'reactstrap';
import StatsBarChart from 'jsx/components/core/form/components/StatsBarChart';
import Statistics from 'jsx/components/modules/cropping/containers/Statistics';

import { fetchTrackStats } from 'jsx/components/modules/vehicles/actions/logger';

class DashboardCanecutter extends React.Component {
  constructor() {
    super();

    this.state = ({
      narrowSidebar: false,
      logo: null
    })

    this.reload = this.reload.bind(this)
  }

  async componentDidMount() {
    // this.reload();
  }

  async reload() {
    this.props.dispatch(fetchTrackStats());
  }

  render() {
    const { currentApp } = this.props.realm;
    const app = currentApp ? currentApp : {};
    const { stats } = this.props.loggers;

    return (
      <div className="p-5 text-center">
        <div>
          <Icon size="4x" name="wave-pulse" style={{ color: '#30B673' }} />
        </div>
        <h1 className="mt-3 mb-2">{app.title} Vitals</h1>
        
        {/* <StatsBarChart data={stats} title="Logger Tracks by Day for Season"/> */}
        <Statistics />

        {/* <Button color="link" onClick={this.reload}>Refresh</Button> */}
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
    loggers: store.loggers
  };
};

export default connect(mapStoreToProps)(DashboardCanecutter);
