import React from 'react';
import { connect } from 'react-redux';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import HarvestGroupsLsv from '../components/HarvestGroupsLsv';
import HarvestGroupModal from './HarvestGroupModal';
import HarvestGroupsToolbar from '../components/HarvestGroupsToolbar';

import { fetchHarvestGroups } from '../actions';

class HarvestGroups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      id: null,
      searchValue: null,
      params: {},
      filters: {},
    };
  
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.setModal = this.setModal.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }

  setModal(row) {
    this.setState({isModalOpen: !this.state.isModalOpen, id: row?.id});
  }

  handleSearchChange(event) {
    this.setState({searchValue: event.target.value});

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    const {
      filters,
      params,
      searchValue
    } = this.state;

    // Add search string to parameters
    params['search_value'] = searchValue;

    // Reload
    this.props.dispatch(fetchHarvestGroups(params, filters));

    this.setState({params});
  }

  onAdd() {
    this.setModal();
  }

  render() {
    const { defaults, harvestgroups } = this.props.season;
    const { isModalOpen, id, searchValue } = this.state;

    return (
      <>
        <HarvestGroupModal setModal={this.setModal} id={id} isOpen={isModalOpen} />
        <HarvestGroupsToolbar
          handleSearchChange={this.handleSearchChange}
          onSearch={this.onSearch}
          searchValue={searchValue}
          onAdd={this.onAdd}
        />

        <HarvestGroupsLsv rows={harvestgroups} defaults={defaults} onClick={this.setModal}/>
      </>
    );
  }
}

const mapStoreToProps = ({ realm, season }) => ({ realm, season });
export default connect(mapStoreToProps)(withContainerError(HarvestGroups));
