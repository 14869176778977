import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import HarvestGroupAssocsLsv from '../components/HarvestGroupAssocsLsv';
import VehiclesLsv from '../../vehicles/components/VehiclesLsv';
import HarvestGroupAssocModal from './HarvestGroupAssocModal';

import FormBase from '../../../core/form/components/FormBase';
import { initControls, saveControls, updateControls } from '../../../core/form/lib/validateForm';
import { controls as harvestgroupControls } from '../forms/harvestgroup';

import { 
  fetchVehicles
} from '../../vehicles/actions/index';

import {
   updateHarvestGroup,
   fetchHarvestGroups,
   createHarvestGroup,
   removeHarvestGroup,
   fetchHarvestGroup,
   fetchHarvestGroupAssocs,
   removeHarvestGroupAssoc
} from '../actions';

class HarvestGroupModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      isAssocModalOpen: false,
      title: 'Harvest Group',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(harvestgroupControls),
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.setAssocModal = this.setAssocModal.bind(this);
    this.onRemoveAssoc = this.onRemoveAssoc.bind(this);
  }

  async componentDidUpdate() {
    const { fetching } = this.props.season;
    const { isOpen } = this.state;
    let { id, isNew, title, controls, data } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false && !fetching) {
      this.setState({isOpen: this.props.isOpen});

      isNew = true;
      title = 'New Harvest Group';
      controls = initControls(cloneDeep(harvestgroupControls));

      id = null;
      data = {};

      // load row if exists
      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = 'Edit Harvest Group';

        data = await this.props.dispatch(fetchHarvestGroup(id));
        controls = updateControls(controls, data);

        await this.props.dispatch(fetchHarvestGroupAssocs({group_id: this.props.id}));
        await this.props.dispatch(fetchVehicles({}, {harvestgroup_id: this.props.id}));
      }

      this.setState({
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls
      });
    }
  }

  setAssocModal(row) {
    this.setState({isAssocModalOpen: !this.state.isAssocModalOpen, id: row?.id});
  }

  async onRemoveAssoc(assoc_id) {
    const confirmed = window.confirm(
      'Removing Harvest Group Association permanently. \n\nThis action can not be undone. Continue?',
    );

    if (confirmed) {
      const success = await this.props.dispatch(removeHarvestGroupAssoc(assoc_id));
      if (success) {
        this.props.dispatch(fetchHarvestGroupAssocs({group_id: this.props.id}));
      }
    }
  }

  async onSave() {
    const { controls, isNew, data } = this.state;
    const formData = saveControls(controls, data);

    let success;
    if (isNew) {
      delete formData.id;
      success = await this.props.dispatch(createHarvestGroup(formData));
    } else {
      success = await this.props.dispatch(updateHarvestGroup(formData));
    }

    if (success) {
      this. props.dispatch(fetchHarvestGroups(this.props.params));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      'Removing Harvest Group permanently. \n\nThis action can not be undone. Continue?',
    );

    if (confirmed) {
      const success = await this.props.dispatch(removeHarvestGroup(data.id));
      if (success) {
        this. props.dispatch(fetchHarvestGroups(this.props.params));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const { controls, isOpen, title, isNew, id, isAssocModalOpen } = this.state;
    const { responseMessage, groupassocs } = this.props.season;
    const { vehicles } = this.props.vehicles;
    const iconName = 'people-group';

    const vehicleFields = ['name', 'type', 'imei', 'updated', 'status'];

    return (
      <Modal isOpen={isOpen}>
        <HarvestGroupAssocModal setModal={this.setAssocModal} isOpen={isAssocModalOpen} group_id={this.props.id} />
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.external_id} />
            <FormInput handleChange={this.handleChange} control={controls.external_mill} />
          </Form>

          {!isNew && (
            <>
              <div className="border-bottom border-corporate text-white mt-3 bg-corporate d-flex justify-content-between p-1">
                <small>ORGANISATION ASSOCIATIONS</small>
              </div>
              <HarvestGroupAssocsLsv rows={groupassocs} onClick={() => {}} onRemove={this.onRemoveAssoc}/>

              <div className="border-bottom border-corporate text-white mt-3 bg-corporate d-flex justify-content-between p-1">
                <small>VEHICLE ASSOCIATIONS</small>
              </div>
              <VehiclesLsv rows={vehicles} orphansByLogger={[]} customFields={vehicleFields} onRowClick={() => {}}/>
            </>
          )}

        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>Save</Button>
            <Button size="sm" color="light" onClick={this.onCancel}>Cancel</Button>
          </div>
          {!isNew && (
            <>
              <Button disabled size="sm" color="danger" onClick={this.onRemove} >Delete</Button>
              <Button size="sm" color="primary" onClick={this.setAssocModal}>Associate Organisation</Button>
              <Button disabled size="sm" color="primary">Associate Vehicle</Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers, season, manage }) => ({
  loggers,
  season,
  manage,
  vehicles
});

export default connect(mapStoreToProps)(HarvestGroupModal);
