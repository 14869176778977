import { cloneDeep } from 'lodash';

import {
  apiAttributeDefs,
  apiInvitations,
  apiOrgs,
  apiRoles,
  apiUsers,
  apiOrgCategories
} from '../../../constants/api';

import { get, post, put, remove, upload } from '../../../lib/genericAction';

export function fetchOrgCategories() {
  return async (dispatch) => get(dispatch, 'FETCH_ORG_CATEGORIES', apiOrgCategories);
}

export function fetchRoles() {
  return async (dispatch) => get(dispatch, 'FETCH_ROLES', apiRoles);
}

export function fetchUsersWithRole(role) {
  return async (dispatch) => get(dispatch, 'FETCH_USERS_WITH_ROLE', `${apiUsers}/roles/${role}`);
}

export function fetchUsers(params, filters) {
  return async (dispatch) => {
    // Apply filters to params
    const updatedParams = cloneDeep(params);
    if (filters?.users?.length > 0)
      Object.keys(filters).forEach((key) => {
        updatedParams[`filter_${key}`] = filters[key];
      });

    return get(dispatch, 'FETCH_USERS', apiUsers, updatedParams);
  };
}

export function fetchPortalUsers() {
  return async (dispatch) => get(dispatch, 'FETCH_PORTAL_USERS', `${apiUsers}/byrole/portalusers`);
}

export function fetchUser(id) {
  return async (dispatch) => get(dispatch, 'FETCH_USER', `${apiUsers}/${id}`);
}

export function fetchUserRoles(id) {
  return async (dispatch) => get(dispatch, 'FETCH_USER_ROLES', `${apiUsers}/${id}/roles`);
}

export function fetchInvitations() {
  return async (dispatch) => get(dispatch, 'FETCH_INVITED', apiInvitations);
}

export function updateUser(data) {
  return async (dispatch) => put(dispatch, 'UPDATE_USER', `${apiUsers}/${data.id}`, data);
}

export function generateUserToken(id) {
  return async (dispatch) => put(dispatch, 'UPDATE_USER_TOKEN', `${apiUsers}/${id}/generate_token`);
}

export function removeUser(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_USER', `${apiUsers}/${id}`);
}

export function removeInvite(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_USERINVITE', `${apiInvitations}/${id}`);
}

export function setUserParams(params) {
  return async (dispatch) => dispatch({ type: 'SET_USER_PARAMS', payload: params });
}

export function setUserFilters(filters) {
  return async (dispatch) => dispatch({ type: 'SET_USER_FILTERS', payload: filters });
}

export function inviteUser(user) {
  return async (dispatch) => post(dispatch, 'INVITE_USER', `${apiInvitations}`, user);
}

export function inviteLink(user) {
  return async (dispatch) => post(dispatch, 'INVITE_LINK', `${apiInvitations}/link`, user);
}

export function fetchInviteOrgs(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_INVITE_ORGS', `${apiInvitations}/orgs/inviting`, params);
}

export function fetchAttributeDefs(params) {
  return async (dispatch) => get(dispatch, 'FETCH_ATTRIBUTE_DEFS', `${apiAttributeDefs}`, params);
}

export function createAttributeDef(data) {
  return async (dispatch) => post(dispatch, 'CREATE_ATTRIBUTE_DEF', `${apiAttributeDefs}`, data);
}

export function updateAttributeDef(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ATTRIBUTE_DEF', `${apiAttributeDefs}/${data.id}`, data);
}

export function removeAttributeDef(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_ATTRIBUTE_DEF', `${apiAttributeDefs}/${id}`);
}

export function fetchOrgs(params, filters) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      params[`filter_${key}`] = filters[key];
    });
  }

  return async (dispatch) => get(dispatch, 'FETCH_ORGS', `${apiOrgs}`, params);
}

export function setOrgParams(params) {
  return async (dispatch) => {
    dispatch({ type: 'SET_ORG_PARAMS', payload: params });
    return dispatch(fetchOrgs(params));
  };
}

export function fetchOrg(id) {
  return async (dispatch) => get(dispatch, 'FETCH_ORG', `${apiOrgs}/${id}`);
}

export function createOrg(data) {
  return async (dispatch) => post(dispatch, 'CREATE_ORG', `${apiOrgs}`, data);
}

export function createClientOrg(data) {
  return async (dispatch) => post(dispatch, 'CREATE_ORG', `${apiOrgs}/client/create`, data);
}

export function updateOrg(data) {
  return async (dispatch) => put(dispatch, 'UPDATE_ORG', `${apiOrgs}/${data.id}`, data);
}

export function removeOrg(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_ORG', `${apiOrgs}/${id}`);
}

export function createUserRole(data) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_USER_ROLE', `${apiUsers}/${data.user_id}/roles`, data);
}

export function removeUserRole(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_USER_ROLE', `${apiUsers}/roles/${id}`);
}

export function createUserOrg(data) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_USER_ORG', `${apiUsers}/${data.user_id}/orgs`, data);
}

export function removeUserOrg(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_USER_ORG', `${apiUsers}/orgs/${id}`);
}
