import { allowed, defaults } from 'jsx/lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    authorised: true,
    authResponseMessage: null,
    error: null,
    responseMessage: null,
    selectedFarm: {},
    selectedBlock: {},
    farms: [],
    blocks: [],
    crops: [],
    seasons: [],
    defaults: {},
    inspectors: [],
    groupassocs: [],
    harvestgroups: [],
    mills: [],
    farmassocs: []
  },
  action,
) {
  // Set expected reducer types
  const types = [
    'FARM',
    'SEASON',
    'GROUP',
    'MILL',
    'INSPECTOR'
  ];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_FARMS_FULFILLED': {
      return {
        ...updatedState,
        farms: action.payload,
      };
    }
    case 'FETCH_FARM_ASSOCS_FULFILLED': {
      return {
        ...updatedState,
        farmassocs: action.payload,
      };
    }
    
    case 'FETCH_HARVEST_GROUPS_FULFILLED': {
      return {
        ...updatedState,
        harvestgroups: action.payload,
      };
    }
    case 'FETCH_FARM_FULFILLED': {
      return {
        ...updatedState,
        selectedFarm: action.payload,
      };
    }
    case 'FETCH_FARM_BLOCKS_FULFILLED': {
      return {
        ...updatedState,
        blocks: action.payload,
      };
    }
    case 'FETCH_FARM_BLOCK_CROPS_FULFILLED': {
      return {
        ...updatedState,
        crops: action.payload,
      };
    }
    case 'FETCH_FARM_BLOCK_FULFILLED': {
      return {
        ...updatedState,
        selectedBlock: action.payload,
      };
    }
    case 'FETCH_HARVEST_GROUP_ASSOCS_FULFILLED': {
      return {
        ...updatedState,
        groupassocs: action.payload,
      };
    }
    case 'FETCH_SEASON_DEFAULTS_FULFILLED': {
      return {
        ...updatedState,
        defaults: action.payload,
      };
    }
    case 'FETCH_SEASONS_FULFILLED': {
      return {
        ...updatedState,
        seasons: action.payload,
      };
    }
    case 'FETCH_MILLS_FULFILLED': {
      return {
        ...updatedState,
        mills: action.payload,
      };
    }
    case 'FETCH_SEASON_INSPECTORS_FULFILLED': {
      return {
        ...updatedState,
        inspectors: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
