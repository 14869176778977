import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import moment from 'moment';

const VehiclesLsv = (props) => {
  const {
    rows,
    orphansByLogger,
    onRowClick,
    customFields
  } = props;

  const renderRows = (headers, row) => {
    let iconColour = 'grey';
    let iconTitle = 'Idle';
    if (row.status) {
      iconColour = row.status.colour;
      iconTitle = row.status.name;
    };

    const enabled = (row.enabled ? 'Yes' : 'No');
    const enabledColour = (row.enabled ? 'Green' : 'Red');

    const type = row?.type?.name;
    const imei = row?.logger?.imei;
    const orphans = orphansByLogger.find(orphan => orphan.logger_id === row?.logger?.id)?.count || 'None';
    const orphanColour = (orphans !== 'None' ? 'red' : 'green');

    const group_name = row?.group?.name;

    let updated_ago = moment.utc(row?.last_track?.created).local().fromNow();
    if (!row?.last_track) updated_ago = '-';

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'icon': return  (
          // <td key={index} className={header.classes} title={iconTitle} ><Icon name={iconName} className="m-0" style={{color: iconColour, width: 16}}/></td>
          <td key={index} className={header.classes} title={iconTitle} ><input className="mt-1" type="checkbox" /></td>
        );
        case 'status': return  (
          <td key={index} className={header.classes} style={{backgroundColor: iconColour, color: 'white'}}>{iconTitle}</td>
        );
        case 'updated': return  (
          <td key={index} className={header.classes} >{updated_ago}</td>
        );
        case 'enabled': return  (
          <td key={index} className={header.classes} style={{color: 'white', backgroundColor: enabledColour}}>{enabled}</td>
        );
        case 'type': return  (
          <td key={index} className={header.classes} >{type}</td>
        );
        case 'imei': return  (
          <td key={index} className={header.classes} >{imei}</td>
        );
        case 'orphans': return  (
          <td key={index} className={header.classes} style={{ color: 'white', backgroundColor: orphanColour}}>{orphans}</td>
        );
        case 'group': return  (
          <td key={index} className={header.classes} >{group_name}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  let headers = [
    {caption: '', field: 'icon', classes:'text-center', style: {width: 12}},
    {caption: 'Vehicle', field: 'name', classes:'text-left'},
    {caption: 'Type', field: 'type', classes:'text-left'},
    {caption: 'Harvester Group', field: 'group', classes:'text-left'},
    {caption: 'Logger IMEI', field: 'imei', classes:'text-left'},
    {caption: 'Last seen', field: 'updated', classes:'text-right'},
    {caption: 'Enabled', field: 'enabled', classes:'text-right'},
    {caption: 'Status', field: 'status', classes:'text-right'},
    {caption: 'Orphans', field: 'orphans', classes:'text-center', style: {width: 30}}
  ];

  // Customise header outside this component. Sort according to order of header fields in array.
  if (customFields?.length > 0) {
    headers = customFields.map(field => {
      return headers.find(header => header.field === field);
    })
  }
  
  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => onRowClick(row)} style={{cursor: 'pointer'}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='tractor';
  const emptyCaption='No Vehicles found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default VehiclesLsv;
