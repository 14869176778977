import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  user_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'user_id',
    type: 'select',
    caption: 'Cane Inspector',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'Cane Inspector User. This is a required field'
  },
  harvestgroup_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'harvestgroup_id',
    type: 'select',
    caption: 'Harvester Group',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'Harvester Group. This is a required field'
  },
  mill_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'mill_id',
    type: 'select',
    caption: 'Assigned Mill',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'Assigned Mill. This is a required field'
  },
  season_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'season_id',
    type: 'select',
    caption: 'Season',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'Season. This is a required field'
  }
};
