import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import { fetchOrgs, fetchPortalUsers } from 'jsx/components/manage/actions';

import FormBase from 'jsx/components/core/form/components/FormBase';
import FormTab from '../../../core/form/components/FormTab';

import PageTitle from '../../../core/form/components/PageTitle';
import SeasonDefaults from './SeasonDefaults';
import OrgsPanel from './Orgs';
import GroupFarms from './GroupFarms';
import HarvestGroups from './HarvestGroups';
import Inspectors from './Inspectors';
import Farms from './Farms';

import { fetchFarms,
  fetchSeasonInspectors,
  fetchFarmAssocs,
  fetchHarvestGroups,
  fetchMills,
  fetchSeasons
} from '../actions';

import { 
  fetchInspectors
} from '../../vehicles/actions';

class Seasons extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'defaults',
    };
  }

  componentDidMount() {
    const { mills } = this.props.season;

    if (mills.length === 0) this.props.dispatch(fetchMills());
  }

  toggleTab(tab) {
    const { farms, harvestgroups, mills, seasons } = this.props.season;
    const { inspectors } = this.props.vehicles;
    const { orgs, portalUsers } = this.props.manage;

    switch (tab) {
      case 'defaults':
        if (mills.length === 0) this.props.dispatch(fetchMills());
        break;

      case 'harvestgroups':
        if (harvestgroups.length === 0) this.props.dispatch(fetchHarvestGroups());
        if (orgs.length === 0) this.props.dispatch(fetchOrgs());
        if (portalUsers.length === 0) this.props.dispatch(fetchPortalUsers());
        break;

      case 'farms':
        this.props.dispatch(fetchFarms({exclude: ['geom']}));
        break;

      case 'orgs':
        if (mills.length === 0) this.props.dispatch(fetchMills());
        this.props.dispatch(fetchOrgs());
        break;

      case 'inspectors':
        if (mills.length === 0) this.props.dispatch(fetchMills());
        if (harvestgroups.length === 0) this.props.dispatch(fetchHarvestGroups());
        if (seasons.length === 0 ) this.props.dispatch(fetchSeasons());
        if (inspectors.length === 0) this.props.dispatch(fetchInspectors());
        this.props.dispatch(fetchSeasonInspectors());
        break;

      case 'farmassocs':
        this.props.dispatch(fetchFarmAssocs());

        if (farms.length === 0) this.props.dispatch(fetchFarms({exclude: ['geom']}));
        if (harvestgroups.length === 0) this.props.dispatch(fetchHarvestGroups());
        if (orgs.length === 0) this.props.dispatch(fetchOrgs());
        if (mills.length === 0) this.props.dispatch(fetchMills());

        break;

      default: break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { defaults } = this.props.season;

    const title = `Season Configuration ${defaults?.active_season?.name}`;

    return (
      <div className="p-2 h-100 d-flex flex-column">
        <div className="m-2">
          <PageTitle title={title} iconName="seedling" />
          <div className="p-1">Manage Application Defaults, Organisations, Farm/Groups associations and Season Cane Inspectors. Admin/Cane Inspector access only.</div>
        </div>

        <Nav tabs>
          <FormTab
            caption="Season Defaults"
            tabId="defaults"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Organisations"
            tabId="orgs"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Harvest Groups/Contacts"
            tabId="harvestgroups"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Farm Associations"
            tabId="farmassocs"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Farms/Blocks"
            tabId="farms"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Cane Inspectors"
            tabId="inspectors"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
        </Nav>

        <TabContent activeTab={this.state.activeTab} className="flex-fill">
          <TabPane tabId="defaults" className="mb-2 p-1 flex-fill">
            <SeasonDefaults />
          </TabPane>

          <TabPane tabId="orgs" className="mb-2 p-3 flex-fill">
            <OrgsPanel />
          </TabPane>

          <TabPane tabId="harvestgroups" className="mb-2 p-3 flex-fill">
            <HarvestGroups />
          </TabPane>

          <TabPane tabId="farmassocs" className="mb-2 p-3 flex-fill">
            <GroupFarms />
          </TabPane>

          <TabPane tabId="farms" className="mb-2 p-1 flex-fill">
            <Farms />
          </TabPane>

          <TabPane tabId="inspectors" className="mb-1 p-1 flex-fill">
            <Inspectors />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ vehicles, season, manage }) => ({ vehicles, season, manage });
export default connect(mapStoreToProps)(withContainerError(Seasons));
