import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  mill_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'mill_id',
    type: 'select',
    caption: 'Mill',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Required Field. Cloning Source and Destination mill'
  },
  source_season_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'source_season_id',
    type: 'select',
    caption: 'Cloning Source Season',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Required Field. Cloning Source and Destination mill'
  },
  destination_season: {
    ...controlDefaults,
    placeholder: '',
    name: 'destination_season',
    type: 'select',
    caption: 'New Season',
    validationRules: {
      isRequired: true
    },
    description: 'Required field.'
  },
};
