import React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import ApplicationDefaults from './ApplicationDefaults';
import SeasonsLsv from '../components/SeasonsLsv';
import SeasonsToolbar from '../components/SeasonsToolbar';
import { fetchSeasons, fetchSeasonInspectors } from '../actions';
import SeasonConfigPanel from './SeasonConfigPanel';
import SeasonCloneModal from './SeasonCloneModal';

class SeasonDefaults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.setModal = this.setModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchSeasons());
    this.props.dispatch(fetchSeasonInspectors());
  }

  setModal() {
    this.setState({isModalOpen: !this.state.isModalOpen});
  }

  render() {
    const { seasons } = this.props.season;
    const {isModalOpen} = this.state;

    return (
      <>
        <SeasonsToolbar onClickClone={this.setModal}/>
        <SeasonCloneModal setModal={this.setModal} isOpen={isModalOpen}/>

        <Row className="m-0">
          <Col>
            <small className="text-corporate">Application Defaults</small>
          </Col>
          <Col className="d-flex justify-content-between">
            <small className="text-corporate">Seasons</small>
          </Col>
          <Col>
            <small className="text-corporate">Season Config</small>
          </Col>
        </Row>
        <Row className="m-0">
          <Col className="m-0 ml-2 mr-2 border border-lightgray bg-light rounded">
            <ApplicationDefaults />
          </Col>
          <Col className="m-0 ml-2 mr-2 border border-lightgray bg-light rounded">
            <SeasonsLsv rows={seasons} />
          </Col>
          <Col className="m-0 ml-2 mr-2 border border-lightgray bg-light rounded">
            <SeasonConfigPanel />
          </Col>
        </Row>
      </>

    );
  }
}

const mapStoreToProps = ({ realm, season }) => ({ realm, season });
export default connect(mapStoreToProps)(withContainerError(SeasonDefaults));
