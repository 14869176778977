import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from "../../core/form/components/Listview";
import FormInput from '../../core/form/components/FormInput';
import { controlDefaults, validateChange, initControls } from "../../core/form/lib/validateForm";

const UserRolesLsv = (props) => {
  const {
    rows,
    userOrgs,
    lookupOptions,
    onAdd,
    onRemove
  } = props;

  let [controls, setControls] = useState(initControls({
    role_id: {
      ...controlDefaults,
      fieldName: 'role_id',
      name: 'role_id',
      type: 'select',
      options: []
    },
    org_id: {
      ...controlDefaults,
      fieldName: 'org_id',
      name: 'org_id',
      type: 'select',
      options: []
    }
  }));
  let [changed, setChanged] = useState(false);

  if (userOrgs?.length > 0) {
    controls.org_id.options = userOrgs.map(userOrg => userOrg.org);
    if (!controls.org_id.value) controls.org_id.value = controls.org_id.options[0].id;
  }

  if (lookupOptions?.length > 0) {
    controls.role_id.options = lookupOptions.map(option => {
      let idx = rows.findIndex(row => row.role_id === option.id && row.org_id === controls.org_id.value)
      if (idx === -1) return option;
      else return false;
    }).filter(Boolean)
    if (!controls.role_id.value) controls.role_id.value = controls.role_id.options[0].id;
  }
  
  const handleChange = (event) => {
    controls = validateChange(event, controls);
    setControls(controls);
    setChanged(!changed);
  };

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'remove': return (
          <td key={index} className={header.classes}><Icon name="trash" style={{ cursor: 'pointer' }} onClick={() => { onRemove(row) }} /></td>
        );
        case 'org_name': return (
          <td key={index} className={header.classes}>{row?.org?.name || '-'}</td>
        );
        default: return (
          <td key={index} className={header.classes}>{row?.role[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Organisation', field: 'org_name', classes: 'text-left' },
    { caption: 'Description', field: 'description', classes: 'text-left' },
    { caption: '', field: 'remove', classes: 'text-center text-danger' },
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName = 'user-tag';
  const emptyCaption = 'No Roles found';

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end bg-light p-1 mb-2 rounded">
        <div className="d-flex d-flex-row ml-2 mr-2">
          <FormInput handleChange={handleChange} control={controls.org_id} />
        </div>
        <div className="d-flex d-flex-row ml-2 mr-2">
          <FormInput handleChange={handleChange} control={controls.role_id} />
        </div>
        <div className="d-flex d-flex-row ml-2 mr-2">
          <Button color="success" size="sm" onClick={() => onAdd({ role_id: controls.role_id.value, org_id: controls.org_id.value })}>Add Role</Button>
        </div>
      </div>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default UserRolesLsv;
