import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Group Name',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'Group Name. This is a required field'
  },
  external_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'external_id',
    type: 'text',
    caption: 'External ID',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'External ID is the group number reference in IMS. This is a required field'
  },
  external_mill: {
    ...controlDefaults,
    placeholder: '',
    name: 'external_mill',
    type: 'text',
    caption: 'External Mill',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'External Mill is the group mill reference in IMS in upper case. This is a required field'
  }
};
