// Set Gateway API parameters from Environment
export const GATEWAY_API_HOST = window.location.hostname;
export const GATEWAY_API_PORT = process.env.REACT_APP_GATEWAY_API_PORT;
export const GATEWAY_API_PROTOCOL = window.location.protocol;

// API Version
const apiVersion = '';

// Hostname based staging detection with staging prefix
const { hostname } = window.location;
const stagingPrefix = 'staging-';

// Set staging environment in localstorage
localStorage.setItem('staging', hostname.substring(0, stagingPrefix.length) === stagingPrefix);

// Set Gateway API URL from Environment
let url = `${GATEWAY_API_PROTOCOL}//${GATEWAY_API_HOST}:${GATEWAY_API_PORT}`;
if (!GATEWAY_API_PORT) {
  url = `${GATEWAY_API_PROTOCOL}//${GATEWAY_API_HOST}`;
}

// Configure socket parameters from Environment
const GATEWAY_API_IO_PORT = process.env.REACT_APP_GATEWAY_IO_PORT;
const GATEWAY_API_IO_PROTOCOL = process.env.REACT_APP_GATEWAY_IO_PROTOCOL || 'http';
const GATEWAY_API_IO_HOST = process.env.REACT_APP_GATEWAY_IO_HOST || 'localhost';

// Set Socket IO url from Environment
let socketUrl = `${GATEWAY_API_IO_PROTOCOL}://${GATEWAY_API_IO_HOST}:${GATEWAY_API_IO_PORT}`;
if (!GATEWAY_API_IO_PORT) {
  socketUrl = `${GATEWAY_API_IO_PROTOCOL}://${GATEWAY_API_IO_HOST}`;
}
export const ioUrl = socketUrl;
export const ioOptions = {
  secure: true,
  reconnect: true,
  reconnectionAttempts: 5,
  transports: ['websocket'],
  path: '/sockets/ws',
};

// API constants
const baseRoute = `${url}/api`;

export const apiRealm = `${baseRoute}/realm`;
export const currentUserUrl = `${baseRoute}/current`;
export const apiLogin = `${baseRoute}/login`;
export const apiLogout = `${baseRoute}/logout`;
export const apiMode = `${baseRoute}/mode`;
export const apiVerify = `${baseRoute}/verify`;
export const apiRegister = `${baseRoute}/register`;
export const apiAssociate = `${baseRoute}/associate`;
export const apiRefresh = `${baseRoute}/refresh`;
export const apiUsers = `${baseRoute}/users`;
export const apiMasquerade = `${baseRoute}/masquerade`;
export const apiRoles = `${baseRoute}/roles`;
export const apiOrgCategories = `${baseRoute}/org/categories`;
export const apiInvitations = `${baseRoute}/invitations`;
export const apiForgot = `${baseRoute}/forgot`;
export const apiReset = `${baseRoute}/reset`;
export const apiOrgs = `${baseRoute}/orgs`;
export const apiFeatureFlags = `${baseRoute}/feature_flags`;
export const apiActivities = `${baseRoute}/activities`;
export const apiAttributeDefs = `${baseRoute}/attribute_defs`;
export const apiAttributes = `${baseRoute}/attributes`;
export const apiListviewConfiguration = `${baseRoute}/users/listviews`;
export const apiTracks = `${baseRoute}/loggers/tracks`;
export const apiLoggers = `${baseRoute}/loggers`;
export const apiVehicles = `${baseRoute}/vehicles`;
export const apiVehicleTypes = `${baseRoute}/lookup/vehicletypes`;
export const apiVehicleStatuses = `${baseRoute}/lookup/attributes/vehicle_status`;
export const apiLoggerTypes = `${baseRoute}/lookup/loggertypes`;
export const apiVehicleOwners = `${baseRoute}/orgs/vehicleowners`;
export const apiMills = `${baseRoute}/orgs/mills`;

export const apiFarms = `${baseRoute}/farms`;
export const apiFarmBlocks = `${baseRoute}/blocks`;
// export const apiGroupAssocs = `${baseRoute}/groups/assocs`;
export const apiHarvestGroups = `${baseRoute}/harvestgroups`;
export const apiSeasons = `${baseRoute}/seasons`;
export const apiDefaults = `${baseRoute}/lookup/defaults`;
export const apiInspectors = `${baseRoute}/inspectors`;
export const apiSeasonInspectors = `${baseRoute}/inspectors/season`;
export const apiRakes = `${baseRoute}/rakes`;
