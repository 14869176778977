import {
  apiFarms,
  apiFarmBlocks,
  apiDefaults,
  apiSeasons,
  apiSeasonInspectors,
  apiHarvestGroups,
  apiMills
} from 'jsx/constants/api';

import { get, put, post, remove} from 'jsx/lib/genericAction';

export function fetchFarms(params) {
  return async (dispatch) => get(dispatch, 'FETCH_FARMS', apiFarms, params);
}

export function fetchFarm(id) {
  return async (dispatch) => get(dispatch, 'FETCH_FARM', `${apiFarms}/${id}`);
}

export function fetchFarmBlock(id) {
  return async (dispatch) => get(dispatch, 'FETCH_FARM_BLOCK', `${apiFarmBlocks}/${id}`);
}

export function fetchFarmAssocs(params = {}) {
  params.exclude = 'geom';
  return async (dispatch) => get(dispatch, 'FETCH_FARM_ASSOCS', `${apiFarms}/assocs`, params);
}

export function fetchFarmAssoc(id) {
  return async (dispatch) => get(dispatch, 'FETCH_FARM_ASSOC', `${apiFarms}/assocs/${id}`);
}

export function updateFarmAssoc(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_FARM_ASSOC', `${apiFarms}/assocs/${data.id}`, data);
}

export function createFarmAssoc(data) {
  return async (dispatch) => post(dispatch, 'CREATE_FARM_ASSOC', `${apiFarms}/assocs`, data);
}

export function fetchFarmBlocks(params, inBackground = true, abortController = null) {
  return async (dispatch) => get(dispatch, 'FETCH_FARM_BLOCKS', apiFarmBlocks, params, null, inBackground, abortController);
}

export function fetchFarmBlockCrops(params, inBackground = true, abortController = null) {
  return async (dispatch) => get(dispatch, 'FETCH_FARM_BLOCK_CROPS', `${apiFarmBlocks}/crops`, params, null, inBackground, abortController);
}

export function fetchHarvestGroups(params) {
  return async (dispatch) => get(dispatch, 'FETCH_HARVEST_GROUPS', apiHarvestGroups, params);
}

export function fetchHarvestGroup(id) {
  return async (dispatch) => get(dispatch, 'FETCH_HARVEST_GROUP', `${apiHarvestGroups}/${id}`);
}

export function updateHarvestGroup(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_HARVEST_GROUP', `${apiHarvestGroups}/${data.id}`, data);
}

export function createHarvestGroup(data) {
  return async (dispatch) => post(dispatch, 'CREATE_HARVEST_GROUP', `${apiHarvestGroups}`, data);
}

export function removeHarvestGroup(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_HARVEST_GROUP', `${apiHarvestGroups}/${id}`);
}

export function fetchHarvestGroupAssocs(params) {
  return async (dispatch) => get(dispatch, 'FETCH_HARVEST_GROUP_ASSOCS', `${apiHarvestGroups}/assocs`, params);
}

export function createHarvestGroupAssoc(data) {
  return async (dispatch) => post(dispatch, 'CREATE_HARVEST_GROUP_ASSOC', `${apiHarvestGroups}/assocs`, data);
}

export function removeHarvestGroupAssoc(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_HARVEST_GROUP_ASSOC', `${apiHarvestGroups}/assocs/${id}`);
}

export function fetchSeasonDefaults() {
  return async (dispatch) => get(dispatch, 'FETCH_SEASON_DEFAULTS', apiDefaults);
}

export function updateSeasonDefaults(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_SEASON_DEFAULTS', `${apiDefaults}/${data.id}`, data);
}

export function fetchSeasons() {
  return async (dispatch) => get(dispatch, 'FETCH_SEASONS', apiSeasons);
}

export function fetchMills() {
  return async (dispatch) => get(dispatch, 'FETCH_MILLS', apiMills);
}

export function fetchSeasonInspectors(params) {
  return async (dispatch) => get(dispatch, 'FETCH_SEASON_INSPECTORS', apiSeasonInspectors, params);
}

export function fetchSeasonInspector(id) {
  return async (dispatch) => get(dispatch, 'FETCH_SEASON_INSPECTOR', `${apiSeasonInspectors}/${id}`);
}

export function updateSeasonInspectorAssoc(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_INSPECTOR_ASSOC', `${apiSeasonInspectors}/${data.id}`, data);
}

export function createSeasonInspectorAssoc(data) {
  return async (dispatch) => post(dispatch, 'CREATE_INSPECTOR_ASSOC', `${apiSeasonInspectors}`, data);
}

export function removeSeasonInspectorAssoc(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_INSPECTOR_ASSOC', `${apiSeasonInspectors}/${id}`);
}

export function fetchSeasonPreflight(mill_id, source_season_id, params) {
  return async (dispatch) => get(dispatch, 'FETCH_CLONE_SEASON_PREFLIGHT', `${apiFarms}/season/clone/preflight/${mill_id}/${source_season_id}`, params);
}

export function cloneSeasonFarms(mill_id, source_season_id, params) {
  return async (dispatch) => get(dispatch, 'CLONE_SEASON_FARMS', `${apiFarms}/season/clone/farms/${mill_id}/${source_season_id}`, params);
}

export function fetchSeasonCompare(mill_id, source_season_id, params) {
  return async (dispatch) => get(dispatch, 'COMPARE_SEASON', `${apiFarms}/season/clone/compare/${mill_id}/${source_season_id}`, params);
}

export function cloneSeasonAssociations(mill_id, source_season_id, params) {
  return async (dispatch) => get(dispatch, 'CLONE_SEASON_ASSOCIATIONS', `${apiFarms}/season/clone/associations/${mill_id}/${source_season_id}`, params);
}

export function cloneSeasonCrops(mill_id, source_season_id, params) {
  return async (dispatch) => get(dispatch, 'CLONE_SEASON_CROP', `${apiFarms}/season/clone/crops/${mill_id}/${source_season_id}`, params);
}
