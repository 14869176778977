import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';

const HarvestGroupsLsv = (props) => {
  const {
    rows,
    onClick,
    onRemove
  } = props;

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'owner_name': return  (
          <td key={index} className={header.classes}>{ row?.org.name }</td>
        );
        case 'primary_contact_name': return  (
          <td key={index} className={header.classes}>{ row?.primary_contact?.name }</td>
        );
        case 'primary_contact_mobile': return  (
          <td key={index} className={header.classes}>{ row?.primary_contact?.mobile }</td>
        );
        case 'delete_icon': return  (
          <td key={index} className={header.classes} title="Remove Association" onClick={() => onRemove(row.id)}><Icon name="trash" className="m-0" style={{color: 'red', width: 16}}/></td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Owner', field: 'owner_name', classes:'text-left'},
    {caption: 'Primary Contact', field: 'primary_contact_name', classes:'text-left'},
    {caption: 'Mobile', field: 'primary_contact_mobile', classes:'text-left'},
    {caption: '', field: 'delete_icon', classes:'text-right'}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => onClick(row)} style={{cursor: 'pointer'}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='people-group';
  const emptyCaption='No Group Associations found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default HarvestGroupsLsv;
