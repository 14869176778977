import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  primary_contact_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'primary_contact_id',
    type: 'select',
    caption: 'Primary Contact',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'Primary Contact for organisation. This is an optional field.'
  },
  owner_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'owner_id',
    type: 'select',
    caption: 'Owner Organisation',
    options:[],
    validationRules: {
      isRequired: true
    },
    description: 'Owner Organisation. This is a required field'
  }
};
