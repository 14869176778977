import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import StatsTile from 'jsx/components/core/form/components/StatsTile';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import { fetchTrackStats } from 'jsx/components/modules/vehicles/actions/logger';
import { fetchRakeStatistics } from 'jsx/components/modules/cropping/actions/rakes';
import { fetchCropStatistics } from 'jsx/components/modules/cropping/actions/harvesting';
import { fetchMills } from 'jsx/components/modules/season/actions';

class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updated: 0
    };

    this.reload = this.reload.bind(this)
  }

  async componentDidMount() {
    this.reload();
  }

  async reload() {
    // this.props.dispatch(fetchTrackStats());

    const tonnes_sjm = await this.props.dispatch(fetchRakeStatistics({filter_mill_id: 'sjm'}));
    const tonnes_mul = await this.props.dispatch(fetchRakeStatistics({filter_mill_id: 'mul'}));
    const area = await this.props.dispatch(fetchCropStatistics());
    const mills = await this.props.dispatch(fetchMills());
    // const tph = (tonnes_mul.tonnes + tonnes_sjm.tonnes) / area.area_harvested;

    const area_harvested_sjm = area.area_harvested.find(harvested => harvested?.external_crush_mill === 'SJM')?.ha;
    const area_harvested_mul = area.area_harvested.find(harvested => harvested?.external_crush_mill === 'MUL')?.ha;

    const area_remaining_sjm = area.area_remaining.find(remaining => remaining?.external_crush_mill === 'SJM')?.ha;
    const area_remaining_mul = area.area_remaining.find(remaining => remaining?.external_crush_mill === 'MUL')?.ha;

    const area_planted_sjm = area.area_planted.find(planted => planted?.external_crush_mill === 'SJM')?.ha;
    const area_planted_mul = area.area_planted.find(planted => planted?.external_crush_mill === 'MUL')?.ha;

    const tpa_sjm = parseFloat(tonnes_sjm.tonnes) / parseFloat(area_harvested_sjm);
    const tpa_mul = parseFloat(tonnes_mul.tonnes) / parseFloat(area_harvested_mul);

    const total_estimate_tonnes_sjm = tpa_sjm * area_remaining_sjm;
    const total_estimate_tonnes_mul = tpa_mul* area_remaining_mul;

    const sjm_attributes = mills.find(mill => mill.tag === 'sjm')?.attributes;
    const sjm_capacity = sjm_attributes?.find(attribute => attribute.tag === 'mill_capacity') || 0;
    const days_sjm = total_estimate_tonnes_sjm / parseFloat(sjm_capacity.value);

    const mul_attributes = mills.find(mill => mill.tag === 'mul')?.attributes;
    const mul_capacity = mul_attributes?.find(attribute => attribute.tag === 'mill_capacity') || 0;
    const days_mul = total_estimate_tonnes_mul / parseFloat(mul_capacity.value);

    this.setState({
      tonnes_sjm,
      tonnes_mul,
      area,
      area_harvested_sjm,
      area_harvested_mul,
      area_remaining_sjm,
      area_remaining_mul,
      area_planted_sjm,
      area_planted_mul,
      tpa_sjm,
      tpa_mul,
      days_sjm,
      days_mul
    });
  }

  render() {
    const { stats } = this.props.loggers;

    return (
      <>
        <Row className="p-0 m-0">
          <StatsTile title="STD Tonnes Crushed SJM" bottomClasses="p-1 bg-pink" value={Math.round(this.state.tonnes_sjm?.tonnes)}/>
          <StatsTile title="STD Area Harvested (ha) SJM" bottomClasses="p-1 bg-indigo" value={Math.round(this.state?.area_harvested_sjm)}/>
          <StatsTile title="STD Area Remaining (ha) SJM" bottomClasses="p-1 bg-primary" value={Math.round(this.state?.area_remaining_sjm)}/>
          <StatsTile title="Planted Area (ha) SJM" bottomClasses="p-1 bg-success" value={Math.round(this.state.area_planted_sjm, 0)}/>
          {/* <StatsTile title="Actual t/ha SJM" bottomClasses="p-1 bg-warning" value={Math.round(this.state.tpa_sjm, 2)}/> */}
          <StatsTile title="Days to go SJM" bottomClasses="p-1 bg-purple" value={Math.round(this.state.days_sjm, 0)}/>
          <StatsTile title="Transfer Cane Crushed @ SJM" bottomClasses="p-1 bg-orange" value={ this.state.tonnes_sjm?.transfer[0]?.tonnes }/>
          
        </Row>
        <Row className="p-0 m-0">
          <StatsTile title="STD Tonnes Crushed MUL" bottomClasses="p-1 bg-pink" value={Math.round(this.state.tonnes_mul?.tonnes)}/>
          <StatsTile title="STD Area Harvested (ha) MUL" bottomClasses="p-1 bg-indigo" value={Math.round(this.state?.area_harvested_mul)}/>
          <StatsTile title="STD Area Remaining (ha) MUL" bottomClasses="p-1 bg-primary" value={Math.round(this.state?.area_remaining_mul)}/>
          <StatsTile title="Planted Area (ha) MUL" bottomClasses="p-1 bg-success" value={Math.round(this.state.area_planted_mul, 0)}/>
          {/* <StatsTile title="Actual t/ha MUL" bottomClasses="p-1 bg-warning" value={Math.round(this.state.tpa_mul, 2)}/> */}
          <StatsTile title="Days to go MUL" bottomClasses="p-1 bg-purple" value={Math.round(this.state.days_mul, 0)}/>
          <StatsTile title="Transfer Cane Crushed @ MUL" bottomClasses="p-1 bg-orange" value={Math.round(this.state.tonnes_mul?.transfer[0]?.tonnes, 0) }/>

        </Row>
        {this.props.showTracks && (
          <Row className="p-0 m-0">
          <StatsTile title="Tracks Received today" bottomClasses="p-1 bg-success" value={stats[stats.length - 1]?.count}/>
        </Row>
        )}
      </>


    );
  }
}

const mapStoreToProps = ({ realm, season, loggers, crop }) => ({ realm, season, loggers, crop });
export default connect(mapStoreToProps)(withContainerError(Statistics));
