import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInputSmall from 'jsx/components/core/form/components/FormInputSmall';
import FormInputColourPicker from 'jsx/components/core/form/components/FormInputColourPicker';
import { controls } from '../forms/defaults';
import { updateSeasonDefaults } from '../actions';

class ApplicationDefaults extends FormBase{
  constructor(props) {
    super(props);

    this.state = {
      defaultsControls: controls,
      data: {},
      message: null
    };

    this.handlePickerChange = this.handlePickerChange.bind(this);
    this.renderAttributes = this.renderAttributes.bind(this);
    this.onApply = this.onApply.bind(this);
    this.handleActiveSeasonChange = this.handleActiveSeasonChange.bind(this);
  }

  async componentDidMount() {
    const {defaults} = this.props.season;
    this.setState({data: defaults});
  };

  handlePickerChange(field, value) {
    const { data } = this.state;

    const idx = data.map_styles.findIndex(style => style.id === field);
    if (idx > -1) data.map_styles[idx].value = value;
  
    this.setState({data});
  }

  // eslint-disable-next-line consistent-return
  componentDidUpdate() {
    const { defaults, seasons } = this.props.season;
    const { defaultsControls } = this.state;

    if (seasons.length > 0 && defaultsControls?.active_season?.options?.length === 0) {
      seasons.map(season => {
        defaultsControls.active_season.options.push({id: season.id, name: season.name})
      });

      defaultsControls.active_season.value = defaults.active_season.id;
      this.setState({defaultsControls});

      return false;
    }
  }

  renderAttributes() {
    const { attributes } = this.props.mapster;

    return attributes.map((attribute, index) => (
      <Row className="m-0 border-bottom border-lightgray p-0 pb-1" key={index}>
        <Col sm={6} className="bg-white m-0 mr-1 p-1 pl-2">{attribute.caption}</Col>
        <Col className="m-0 p-0">
          <FormInputColourPicker value={attribute.value} fieldName={attribute.id} onChange={this.handlePickerChange}/>
        </Col>
      </Row>
    ));
  }

  handleActiveSeasonChange(event) {
    const { data, defaultsControls } = this.state;

    const { value } = event.target;

    data.active_season_id = value;
    defaultsControls.active_season.value = value;

    this.setState({data, defaultsControls});
  }

  // eslint-disable-next-line class-methods-use-this
  async onApply() {
    const { data } = this.state;
    const { defaults } = this.props.season;

    const confirmed = window.confirm(
      'Applying these changes will change map and season defaults for all users of this application. Continue?',
    );

    if (confirmed) {
      const formData = {id: data.id, map_styles: data.map_styles};

      // if ( data.active_season_id !== defaults.active_season_id ) formData.active_season_id = data.active_season_id;
      formData.active_season_id = data.active_season_id;

      const success = await this.props.dispatch(updateSeasonDefaults(formData));
      if (success) {
        this.props.dispatch({ type: 'SET_MAP_STYLES', payload: defaults.map_styles });
        this.setState({message: 'DEFAULTS SAVED Successfully!'});

        setTimeout(() => this.setState({message: null}), 3000);
      }
    }
  }

  render() {
    const { defaultsControls, message } = this.state;

    return (
      <div>
        <div style={{fontSize: '0.7875rem'}} className="m-1">
          <div className="mt-2 border-bottom border-corporate">
            <small>Global Application Settings</small>
          </div>
          <Row className="m-0 border-bottom border-lightgray p-0 pb-1">
            <Col sm={6} className="bg-white m-0 mr-1 p-1 pl-2">Active Season</Col>
            <Col className="m-0 p-0">
              <FormInputSmall
                handleChange={this.handleActiveSeasonChange}
                control={defaultsControls.active_season}
                className="d-flex justify-content-between m-0"
                hideCaption
              />
            </Col>
          </Row>

          <div className="mt-2 border-bottom border-corporate">
            <small>Global Mapstyle</small>
          </div>
          {this.renderAttributes()}
        </div>

        <small className="d-flex justify-content-center p-2 text-danger">PLEASE NOTE: Any changes made on Global Styles and settings will affect all users of this application.</small>
        <div className="d-flex justify-content-center m-2">
          <Button size="sm" color="success" onClick={this.onApply}>Apply Changes</Button>
        </div>

        {message && (
          <div className="text-success text-center p-1"><small>{message}</small></div>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ realm, mapster, season }) => ({ realm, mapster, season });
export default connect(mapStoreToProps)(withContainerError(ApplicationDefaults));
