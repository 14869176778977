import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';

const HarvestGroupAssocsLsv = (props) => {
  const {
    rows,
    onClick
  } = props;

  const renderRows = (headers, row) => {
    const owner_name = (row?.harvestgroup_assocs?.length > 0 ? row?.harvestgroup_assocs[0].org.name : '-');
    const primary_contact_name = (row?.harvestgroup_assocs?.length > 0 ? row?.harvestgroup_assocs[0]?.primary_contact?.name : '-');
    const primary_contact_mobile = (row?.harvestgroup_assocs?.length > 0 ? row?.harvestgroup_assocs[0]?.primary_contact?.mobile : '-');

    const tableTd = headers.map((header, index) => {

      switch (header.field) {
        case 'owner_name': return  (
          <td key={index} className={header.classes}>{owner_name}</td>
        );
        case 'primary_contact_name': return  (
          <td key={index} className={header.classes}>{primary_contact_name}</td>
        );
        case 'primary_contact_mobile': return  (
          <td key={index} className={header.classes}>{primary_contact_mobile}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Group', field: 'name', classes:'text-left'},
    {caption: 'Owner Organisation', field: 'owner_name', classes:'text-left'},
    {caption: 'Primary Contact', field: 'primary_contact_name', classes:'text-left'},
    {caption: 'Mobile', field: 'primary_contact_mobile', classes:'text-left'}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => onClick(row)} style={{cursor: 'pointer'}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='people-group';
  const emptyCaption='No Harvest Groups found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default HarvestGroupAssocsLsv;
