import { Button } from 'reactstrap';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';

const InspectorsToolbar = (props) => {
  const {
    handleSearchChange,
    onSearch,
    searchValue,
    onAdd
  } = props;

  return (
    <div className="bg-light rounded p-1 m-0 d-flex justify-content-end">
      <SingleSearch placeholder="Partial Keyword Search" handleSearchChange={handleSearchChange} onSearch={onSearch} value={searchValue}/>
      <Button size="sm" color="success" onClick={onAdd}>Add New</Button>
    </div>
  );
};

export default InspectorsToolbar;
