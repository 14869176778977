import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FarmsLsv from '../components/FarmsLsv';
import FarmMap from './FarmMap';
import { fetchFarm, fetchFarms } from '../actions';
import FarmsToolbar from '../components/FarmsToolbar';

const HEIGHT_OF_HEADER = '210px';

class Farms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: null,
      params: {},
      filters: {},
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(farm) {
    this.props.dispatch(fetchFarm(farm.id));
  }

  handleSearchChange(event) {
    this.setState({searchValue: event.target.value});

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    const {
      filters,
      params,
      searchValue
    } = this.state;

    // Add search string to parameters
    params['search_value'] = searchValue;

    this.props.dispatch(fetchFarms(params, filters));

    this.setState({params});
  }

  render() {
    const { farms, selectedFarm } = this.props.season;
    const { searchValue } = this.state;

    return (
      <>
        <FarmsToolbar
          handleSearchChange={this.handleSearchChange}
          onSearch={this.onSearch}
          searchValue={searchValue}
        />

        <Row className="m-0 p-0 h-100">
          <Col className="h-100">
            <div className="" style={{overflowY: 'auto', height: `calc(100vh - ${HEIGHT_OF_HEADER})`}} >
              <FarmsLsv rows={farms} onRowClick={this.onRowClick} selectedFarm={selectedFarm}  />
            </div>
          </Col>
          <Col sm={4}>
            <FarmMap />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStoreToProps = ({ realm, season }) => ({ realm, season });
export default connect(mapStoreToProps)(withContainerError(Farms));
