import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

import FormInputSelect from '../../../core/form/components/FormInputSelect';
import FormBase from '../../../core/form/components/FormBase';

import { saveControls } from '../../../core/form/lib/validateForm';
import { controls as cloningControls } from '../forms/season_cloning';

import {
   fetchSeasonPreflight,
   cloneSeasonFarms,
   fetchSeasonCompare,
   cloneSeasonAssociations,
   cloneSeasonCrops
} from '../actions';

class SeasonCloneModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Season Cloning',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(cloningControls),
      preflight: {messages: [], pass: false},
      disableControls: false,
      compare: {}
    };

    this.onPreflight = this.onPreflight.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCloneFarms = this.onCloneFarms.bind(this);
    this.onCloneAssociations = this.onCloneAssociations.bind(this);
    this.onCloneCrops = this.onCloneCrops.bind(this);
    this.renderStatusLabels = this.renderStatusLabels.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
  }

  async componentDidUpdate() {
    const { fetching, mills, seasons } = this.props.season;
    const { isOpen } = this.state;
    let { id, isNew, title, controls, data } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false && !fetching) {
      this.setState({isOpen: this.props.isOpen});

      isNew = true;
      title = 'Season Cloning...';

      id = null;
      data = {};

      // Load combos
      const noneSelected = [{id: null, name: 'None Selected'}];
      controls.mill_id.options = noneSelected.concat(mills.map(mill => ({id: mill.id, name: mill.name})));
      controls.source_season_id.options = noneSelected.concat(seasons.map(season => ({id: season.id, name: season.name})));
      
      const destination_seasons = this.deriveSeasons();
      controls.destination_season.options = noneSelected.concat(destination_seasons.map(season => ({id: season, name: season}))); 

      controls.mill_id.value = null;
      controls.source_season_id.value = null;
      controls.destination_season.value = null;

      this.setState({
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls,
        preflight: {messages: [], pass: false},
        compare: {},
        disableControls: false
      });
    }
  }

  deriveSeasons() {
    const seasons = []
    const season_count = 9;

    for (let s = 0; s < season_count; s++) {
      const year = moment().add(s, 'year').format('YYYY');
      if (year !== '2024') seasons.push(year)
    }

    return seasons;
  }

  async onCloneAssociations () {
    const { controls, data } = this.state;
    const formData = saveControls(controls, data);

    const okay = window.confirm('Cloning Associations from Source Season to Destination Season. Continue?');
    if (okay) {
      await this.props.dispatch(cloneSeasonAssociations(formData.mill_id, formData.source_season_id, {destination_season: formData.destination_season}));
      await this.onPreflight();
    };
  }

  async onCloneCrops() {
    const { controls, data } = this.state;
    const formData = saveControls(controls, data);

    const okay = window.confirm('Creating Crops from Destination Season Farm Blocks. Continue?');
    if (okay) {
      await this.props.dispatch(cloneSeasonCrops(formData.mill_id, formData.source_season_id, {destination_season: formData.destination_season}));
      await this.onPreflight();
    };
  }

  async onCloneFarms() {
    const { controls, data } = this.state;
    const formData = saveControls(controls, data);

    const okay = window.confirm('Cloning farms/blocks from Source Season to Destination Season. Continue?');
    if (okay) {
      await this.props.dispatch(cloneSeasonFarms(formData.mill_id, formData.source_season_id, {destination_season: formData.destination_season}));
      await this.props.dispatch(fetchSeasonCompare(formData.mill_id, formData.source_season_id, {destination_season: formData.destination_season}));
      await this.onPreflight();
    };
  }

  async onPreflight() {
    const { controls, data } = this.state;
    const formData = saveControls(controls, data);

    const source_season =  controls.source_season_id.options.find(option => option.id === controls.source_season_id.value).name;
    const destination_season =  controls.destination_season.options.find(option => option.id === controls.destination_season.value).name;

    let valid = (parseInt(source_season) !== parseInt(destination_season));
    if (!valid) {
      window.confirm('Source and New season can not be the same!');
      return false;
    }

    // Validate fields before preflight
    valid = (formData.mill_id, formData.season_season_id, formData.destination_season);
    if (!valid) {
        window.confirm('All fields in this form must be filled in!');
        return false;
    }

    // Disable all input controls
    this.setState({disableControls: true});

    const preflight = await this.props.dispatch(fetchSeasonPreflight(formData.mill_id, formData.source_season_id, {destination_season: formData.destination_season}));
    const compare = await this.props.dispatch(fetchSeasonCompare(formData.mill_id, formData.source_season_id, {destination_season: formData.destination_season}));

    this.setState({preflight, compare});
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  renderPreflight() {
    const { preflight } = this.state;

    return preflight.messages.map((message, index) => {
      const rowClass = (message.pass ? 'text-black m-1' : 'text-black m-1');
      const iconName = (message.pass ? 'check' : 'xmark');

      return (
        <div className={rowClass} key={index}>
          <Icon size="1x" name={iconName} className="mr-2" />
          {message.comment}
        </div>
      );
    });
  }

  renderStatusLabels() {
    const { compare } = this.state;
    return (
      <>
        {compare.clone_farms === 0 && compare.farms === 0 && compare.clone_farm_blocks === 0 && compare.farm_blocks === 0 && (<div className="m-1 text-warning">No Farms/Blocks found for NEW season.</div>)}

        {compare.clone_farms > 0 && compare.farms !== compare.clone_farms && (<div className="m-1 text-success">Farms are cloning. Waiting for replication to complete. Reload button to update status.</div>)}

        {compare.clone_farm_blocks > 0 && compare.farm_blocks !== compare.clone_farm_blocks && (<div className="m-1 text-success">Blocks are cloning. Waiting for replication to complete. Reload button to update status.</div>)}

        {compare.clone_farm_block_crops > 0 && compare.farm_block_crops !== compare.clone_farm_block_crops && (<div className="m-1 text-success">Crops are being created. Waiting for replication to complete. Reload button to update status.</div>)}

        {compare.farms > 0 && compare.clone_farms === compare.farms && compare.farm_blocks > 0 && compare.farm_blocks === compare.clone_farm_blocks && (<div className="m-1 text-success">Farms and Blocks have been cloned for this season.</div>)}

        {compare.destination_inspector_assocs > 0 && compare.destination_farm_assocs > 0 && (<div className="m-1 text-success">Associations have been completed for this season</div>)}

        {compare.destination_inspector_assocs === 0 && compare.destination_farm_assocs === 0 && (<div className="m-1 text-warning">No Associations found for NEW season</div>)}

        {compare.farm_block_crops > 0 && (<div className="m-1 text-success">Farm Block Crops have been cloned</div>)}

        {compare.farm_block_crops === 0 && compare.clone_farm_block_crops === 0 && (<div className="m-1 text-warning">No Block Crops found for NEW season</div>)}
      </>
    );
  }

  renderButtons() {
    const { compare } = this.state;
    return (
      <>
        <Button size="sm" className="mr-2" color="primary" onClick={this.onPreflight}>Check Season Data/Reload</Button>

        {compare.farms === 0 && compare.farm_blocks === 0 && compare.clone_farms === 0 && compare.clone_farm_blocks === 0 && (<Button size="sm" className="mr-2" color="success" onClick={this.onCloneFarms}>Clone Farms</Button>)}

        {compare.farm_blocks > 0 && compare.destination_inspector_assocs === 0 && compare.destination_farm_assocs === 0 && (<Button size="sm" className="mr-2" color="success" onClick={this.onCloneAssociations}>Clone Associations</Button>)}

        {compare.farm_blocks > 0 && compare.farm_block_crops === 0 && compare.clone_farm_block_crops === 0 && (<Button size="sm" className="mr-2" color="success" onClick={this.onCloneCrops}>Create Block Crops</Button>)}

        <Button size="sm" color="light" onClick={this.onCancel}>Close</Button>
      </>
    )
  }

  render() {
    const { controls, isOpen, title, preflight, disableControls } = this.state;
    const { responseMessage, fetching } = this.props.season;

    const iconName = 'leaf';

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'mill_id'}  })
              }
              control={controls.mill_id}
              isDisabled={disableControls}
            />
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'source_season_id'}  })
              }
              control={controls.source_season_id}
              isDisabled={disableControls}
            />

            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'destination_season'}  })
              }
              control={controls.destination_season}
              isDisabled={disableControls}
            />
          </Form>

          {preflight.messages.length > 0 && (
            <div className="mt-3">
              <small className="ml-2 text-corporate">SEASON CLONING VALIDATION CHECKS</small>
            
              <div className="border border-gray bg-light p-1 rounded">
                {this.renderPreflight()}
              </div>

              {this.renderStatusLabels()}
            </div>
          )}

        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          {!fetching && (
            <div>
              {this.renderButtons()}
            </div>
          )}

          {fetching && (<HalfCircleSpinner className="align-self-center" color="#4285f4" size={20} />)}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers, season, manage }) => ({
  vehicles,
  loggers,
  season,
  manage
});

export default connect(mapStoreToProps)(SeasonCloneModal);
