import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';
import TrackingVehicleRow from './TrackingVehicleRow';

const TrackingVehiclesLsv = (props) => {
  const {
    rows,
    onRowClick,
    toggleTails,
    vehiclesWithTails,
    selectedVehicle,
    settings,
    getVehicleColour,
    component
  } = props;

  const fontSize = 11;

  const renderRows = (headers, row) => {

    let iconColour = 'grey';
    let iconTitle = 'Idle';
    if (row.status) {
      iconColour = getVehicleColour(component, row.status?.tag);
      iconTitle = row.status.name;
    };

    let updated_ago = moment.utc(row?.last_track?.created).local().fromNow();
    if (!row?.last_track) updated_ago = '-';

    let { name } = row;
    let iconName = null;
    if (row.type) {
      name = `${row?.name} (${row.type.name})`;
      iconName = row.type.icon;
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'icon': return  (
          <td key={index} className={header.classes} title={iconTitle} ><Icon name={iconName} className="m-0" style={{color: iconColour, width: 16}}/></td>
        );
        case 'status': return  (
          <td key={index} className={header.classes} style={{backgroundColor: iconColour, color: 'white'}}>{iconTitle}</td>
        );
        case 'updated': return  (
          <td key={index} className={header.classes} style={header.style}>{updated_ago}</td>
        );
        case 'name': return  (
          <td key={index} className={header.classes} style={header.style}>{name}</td>
        );
        default: return  (
          <td key={index} className={header.classes} style={header.style}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: '', field: 'icon', classes:'text-center', style: {width: 12}},
    {caption: 'Vehicle', field: 'name', classes:'text-left', style: {fontSize}},
    {caption: 'Last seen', field: 'updated', classes:'text-right', style: {fontSize, width: 100}},
    {caption: 'Status', field: 'status', classes:'text-center', style: {fontSize, width: 70}}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);

  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <TrackingVehicleRow
        key={index}
        renderRows={renderRows}
        headers={headers}
        row={row} 
        onClick={onRowClick}
        toggleTails={toggleTails}
        vehiclesWithTails={vehiclesWithTails}
        selectedVehicle={selectedVehicle}
        settings={settings}
      />
    );
  }

  const iconName='tractor';
  const emptyCaption='No Vehicles found';
  const darkTable = true;

  return (
    <React.Fragment>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
        darkTable={darkTable}
      />
    </React.Fragment>
  );
};

export default TrackingVehiclesLsv;
