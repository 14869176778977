import { Button } from 'reactstrap';

const SeasonsToolbar = (props) => {
  const {
    onClickClone
  } = props;

  return (
    <div className="bg-light rounded p-1 m-2 d-flex justify-content-end border border-lightgray">
      <Button size="sm" color="success ml-2" onClick={onClickClone}>Clone Season</Button>
    </div>
  );
};

export default SeasonsToolbar;
